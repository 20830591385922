import { graphql } from "gatsby"
import React, { useContext, useEffect } from "react"
import { useThemeUI } from "theme-ui"

import FeaturedCases from "../components/featured-cases"
import { InterfaceContext } from "../context/interface"
import { getCaseKey } from "../utils/get-case-key"
import { partitionCases } from "../utils/partition-cases"

const FeaturedCasesTemplate = ({ data, location, pageContext }) => {
  const { setTranslationLinks } = useContext(InterfaceContext)
  const { colorMode, setColorMode } = useThemeUI()
  useEffect(() => {
    setTranslationLinks(pageContext.canonicals)
    if (pageContext.key === "Design") {
      setColorMode("midnight")

      return () => {
        setColorMode("morning")
      }
    }
  }, [])

  const partitioned = partitionCases(data.featured.cases)
  const keys = Object.entries(partitioned).map(([next]) => next)
  const caseKey = pageContext.key
  const departmentIntro = data.intro
  const aboveContent = departmentIntro.text.childMarkdownRemark.html

  return (
    <FeaturedCases
      nav={data.navigation}
      dropup={data.dropup}
      subNav={data.subnav}
      footerNav={data.footerNavigation}
      isIndex
      aboveContent={aboveContent}
      caseCategories={keys}
      caseKey={caseKey}
      cases={partitioned[caseKey]}
      data={data}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default FeaturedCasesTemplate

export const featuredQuery = graphql`
  query FeaturedCasesContent(
    $slug: String!
    $case_slug: String!
    $locale: String!
    $key: String!
  ) {
    intro: contentfulTextColumn(
      node_locale: { eq: $locale },
      name: { eq: $key }
    ) {
      name
      text {
        text
        childMarkdownRemark {
          html
        }
      }
    }
    subnav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...navigationFields
    }
    dropup: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Services" }
    ) {
      ...navigationFields
    }
    navigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      ...navigationFields
    }
    footerNavigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...footerNavigationFields
    }
    currentCase: contentfulCase(slug: { eq: $case_slug }) {
      ...casePreviewFields
    }
    featured: contentfulFeaturedCases(slug: { eq: $slug }) {
      id
      name
      slug
      cases {
        ...casePreviewFields
      }
    }
  }
`
